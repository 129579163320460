<template>
  <BModal ref="success-modal" centered hide-header hide-footer @hidden="handleHidden">
    <BRow class="px-1 py-2">
      <BCol cols="12" class="d-flex justify-content-center mb-1">
        <BImg
          src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
        />
      </BCol>
      <BCol cols="12" class="text-center d-flex justify-content-center my-1">
        <h4 class="text-black">
          <strong>
              {{ orders }} order berhasil ditambahkan
          </strong>
        </h4>
      </BCol>
      <BCol cols="12" class="d-flex justify-content-center">
        <BButton variant="primary" @click="moveDataOrder">
          <h5 class="mb-0 text-white">
            <strong>
              Lihat Data Order
            </strong>
          </h5>
        </BButton>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
export default {
  props: ['orders'],
  methods: {
    show() {
      this.$refs['success-modal'].show()
    },
    moveDataOrder() {
      this.$router.push('data-order')
    },
    handleHidden() {
      window.location.reload()
    },
  },
}
</script>

<style>

</style>
